<template>
    <main-template>
        <template v-slot:headerLottieDark>
            <dotlottie-player :src="getDarkLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:headerLottieLight>
            <dotlottie-player :src="getLightLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal" ></dotlottie-player>
        </template>
        <template v-slot:header>Hub</template>
        <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
<!--                            <Hr-navigation :links="slotProps.links"/>-->
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (userDetails || loadingDetails) && !hideTable}">
                                <loading v-if="!response"></loading>
                                <template v-else>
                                    <div class="overflow-auto h-full w-full">
                                    <blank-header>
                                        <template v-slot:title>
                                            Day Pay Requests
                                        </template>
                                    </blank-header>
                                        <day-pay-requests-paginated-list :response="response"></day-pay-requests-paginated-list>
                                    </div>

                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v3/templates/MainTemplate.vue";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import FormAction from "@/v2/components/FormAction";
import ClientUserGroupsPaginatedList from "@/v2/paginatedLists/ClientUserGroupsPaginatedList";
import DayPayRequestsPaginatedList from "@/v2/paginatedLists/DayPayRequestsPaginatedList.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrPersonSingle from "@/v3/components/HrPersonSingle.vue";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
export default {
    name: "dayPayRequests",
    data() {
        return {
            loading: false
        }
    },
    components: {
        HrNavigation, HrPersonSingle, HrPeoplePagination,
        BlankHeader,
        DayPayRequestsPaginatedList,
        ClientUserGroupsPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_light"
        },
    }
}
</script>

<style scoped>

</style>
